// Core variables and mixins
@charset "UTF-8";

@import "bourbon";
@import "variables";
@import "mixins";

$primary-color: #FF9D00;

body {
    > .pusher {
        background-color: #fff !important;
        //background-size: cover !important;
    }
}

header {
    //@include linear-gradient(lighten($primary-color, 10) 5%, darken($primary-color, 10) 100%);
    background-color: $primary-color;
    box-shadow: 0 3px 3px 0 rgba(darken($primary-color, 20), 0.5);
    padding: 10px;
    margin-bottom: 1rem;

    .logo {
        a {
            display: block;
            background-image: url(../images/universoemoticono/logo.png);
            $fwidth: 416px;
            $fheight: 107px;
            width: $fwidth / 2;
            height: $fheight / 2;
            @include media-retina {
                background-image: url(../images/universoemoticono/logo@2x.png);
            }
            @include media-sm {
                width: $fwidth;
                height: $fheight;
            }

            margin: 0 auto !important;

            @include media-sm {
                margin-bottom: 0 !important;
            }
        }
    }

}

#menu {
    margin-bottom: 0 !important;
}

.item.sidebar-menu {
    display: none !important;
}

.ui.primary.buttons .button, .ui.primary.button, .ui.blue.button {
    background-color: $primary-color;

    &:hover {
        background-color: darken($primary-color, 10);
    }
    &:active {
        background-color: darken($primary-color, 20);
    }
}

.ui.stackable.grid {
    > .row {
        > .column {
            padding-bottom: 0 !important;
            @include media-sm {
                padding-bottom: 1rem !important;
            }
        }
    }
}

.ui.card:not(.horizontal) {
    flex-direction: row;
    @include media-sm {
        flex-direction: column;
    }

    > * {
        flex: 1 0 50% !important;;
    }

    .image {
        border-radius: 0.28571429rem 0em 0em 0.28571429rem !important;
        max-width: 150px;
        border-right: 1px solid rgba(34, 36, 38, 0.0980392);

        @include media-sm {
            max-width: 100%;
            border-radius: 0.28571429rem 0.28571429rem 0em 0em !important;
        }
    }

    .content {
        border-top-width: 0;

        @include media-sm {
            border-top-width: 1px;
            border-right-width: 0;
        }
    }

}

footer {
    $color: #002534;
    background-color: $color !important;

    .inverted a {
        &:hover {
            text-decoration: underline;
        }
    }
}
